import {
  ref, onMounted, getCurrentInstance, computed
} from 'vue'
import { reportingUseCase } from '@/domain/usecase'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import { formingPayloadGenerateReport } from '@/utils/helpers/returnPayloadGenerateReport'

export default {
  setup() {
    const app = getCurrentInstance()
    const store = useStore()
    const { $toast } = app!.appContext.config.globalProperties
    const data = ref([])
    const route = useRoute()
    const router = useRouter()
    const inputSearch = ref('')
    const iconPath = ref('')
    const dataFilter = computed(() => data.value.filter((item: any) => item.Title.toLowerCase().includes(inputSearch.value.toLowerCase())))
    const filters = {
      pageNumber: 1,
      pageSize: 10,
      totalRecords: 0,
      search: '',
      filterField: ['title'],
      custom: [] as any
    }
    const isReportExcel = () => {
      // eslint consistent-return
      // eslint no-else-return: ["error", {allowElseIf: true}]
      if (route.name === 'reporting-print') {
        iconPath.value = require('@/assets/img/icon/printer.svg')
        return false
      }
      iconPath.value = require('@/assets/img/icon/excel-green.svg')
      return true
    }

    const getAllData = async () => {
      store.dispatch('showLoading')
      let response
      if (isReportExcel()) {
        filters.custom = [['type', '=', 'excel']]
        response = await reportingUseCase.getAll(filters)
      } else {
        filters.custom = [['type', '=', 'report']]
        response = await reportingUseCase.getAll(filters)
      }
      console.log('response', response)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
      } else {
        filters.totalRecords = response.result.Count
        data.value = await response.result.Data
      }
      store.dispatch('hideLoading')
    }

    const getUrl = async (val: any) => {
      const response = await reportingUseCase.getUrlReport(val)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
      } else {
        console.log('else ', response.result)
      }
    }

    const changeSearch = (val: any) => {
      console.log('search val', val)
      filters.search = val
      filters.pageNumber = 1
      getAllData()
    }

    const showDetailReport = async (val: any) => {
      console.log('val', val)
      if (isReportExcel()) {
        if (store.getters['appActiveUser/getAgent']?.id) {
          router.push({ name: 'reporting-data-detail', params: { id: val.Id }, query: { title: val.Title } })
        } else {
          router.push({ name: 'reporting-data-detail-ansurance', params: { id: val.Id }, query: { title: val.Title } })
        }
      } else {
        try {
          store.dispatch('showLoading')
          const response = await reportingUseCase.getReportParams(val.Id)
          if (!response.error) {
            console.log('store', store.state)
            console.log('response', response.result)
            const payload = await formingPayloadGenerateReport(response.result)
            const res = await reportingUseCase.generateReport(val.Id, { params: payload })
            if (!response.error) {
              console.log('res', res)
              const routeData = router.resolve({ name: 'report', params: { Id: encryptDecriptMethods.encrypt(res.result.Data), jenisReport: 'advance-report' } })
              window.open(routeData.href, '_blank')
            }
          }
          store.dispatch('hideLoading')
        } catch (error) {
          console.log('error', error)
          store.dispatch('hideLoading')
        }
        // const res = await reportingUseCase.generateReport(val.Id)
        // if (response.error) {
        //   $toast.add({
        //     severity: 'error', detail: response.message, group: 'bc', life: 3000
        //   })
        // } else {
        //   console.log('response', response)
        // }
        // const routeData = router.resolve({ name: 'report', params: { Id: encryptDecriptMethods.encrypt(val.Query), jenisReport: 'advance-report' } })
        // window.open(routeData.href, '_blank')
      }
    }

    const onPage = (event: any) => {
      filters.pageNumber = event.page + 1
      getAllData()
    }

    onMounted(() => {
      getAllData()
    })
    return {
      data,
      inputSearch,
      iconPath,
      changeSearch,
      showDetailReport,
      dataFilter,
      filters,
      onPage
    }
  }
}
